import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['toggle']

  submit() {

    let url = this.toggleTarget.dataset["url"]
    console.log(url)

    let column = this.toggleTarget.dataset["column"]
    console.log(column)

    let checked = this.toggleTarget.checked
    console.log(checked)

    let data = new FormData()
    data.append('value', checked)
    data.append('column', column)

    const request = new FetchRequest('post', url, {
      body: data
    })
    request.perform()
  }
}